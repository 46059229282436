import { default as _91_46_46_46slug_93OqAqzXdhyjMeta } from "/home/loic/Web/carpilot/carpilot/pages/agences/[...slug].vue?macro=true";
import { default as indexKTuUNPMrKtMeta } from "/home/loic/Web/carpilot/carpilot/pages/agences/index.vue?macro=true";
import { default as contactxx1qEoshnKMeta } from "/home/loic/Web/carpilot/carpilot/pages/contact.vue?macro=true";
import { default as estimation_45en_45ligne2WFLnbg9DNMeta } from "/home/loic/Web/carpilot/carpilot/pages/estimation-en-ligne.vue?macro=true";
import { default as indexqJAMICIURxMeta } from "/home/loic/Web/carpilot/carpilot/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93eDlpT0yYNUMeta } from "/home/loic/Web/carpilot/carpilot/pages/legal/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_9390teRUiSFoMeta } from "/home/loic/Web/carpilot/carpilot/pages/nos-astuces/[...slug].vue?macro=true";
import { default as indexKBx3oaopywMeta } from "/home/loic/Web/carpilot/carpilot/pages/nos-astuces/index.vue?macro=true";
import { default as prendre_45rendez_45vousaSxQJr5LMEMeta } from "/home/loic/Web/carpilot/carpilot/pages/prendre-rendez-vous.vue?macro=true";
import { default as signez_45vos_45documentsRwlOtXiFCPMeta } from "/home/loic/Web/carpilot/carpilot/pages/signez-vos-documents.vue?macro=true";
import { default as _91_46_46_46slug_93FffiyYNzcpMeta } from "/home/loic/Web/carpilot/carpilot/pages/votre-ville/[...slug].vue?macro=true";
export default [
  {
    name: "agences-slug",
    path: "/agences/:slug(.*)*",
    component: () => import("/home/loic/Web/carpilot/carpilot/pages/agences/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "agences",
    path: "/agences",
    component: () => import("/home/loic/Web/carpilot/carpilot/pages/agences/index.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/loic/Web/carpilot/carpilot/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "estimation-en-ligne",
    path: "/estimation-en-ligne",
    component: () => import("/home/loic/Web/carpilot/carpilot/pages/estimation-en-ligne.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/loic/Web/carpilot/carpilot/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-slug",
    path: "/legal/:slug(.*)*",
    component: () => import("/home/loic/Web/carpilot/carpilot/pages/legal/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "nos-astuces-slug",
    path: "/nos-astuces/:slug(.*)*",
    component: () => import("/home/loic/Web/carpilot/carpilot/pages/nos-astuces/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "nos-astuces",
    path: "/nos-astuces",
    component: () => import("/home/loic/Web/carpilot/carpilot/pages/nos-astuces/index.vue").then(m => m.default || m)
  },
  {
    name: "prendre-rendez-vous",
    path: "/prendre-rendez-vous",
    component: () => import("/home/loic/Web/carpilot/carpilot/pages/prendre-rendez-vous.vue").then(m => m.default || m)
  },
  {
    name: "signez-vos-documents",
    path: "/signez-vos-documents",
    component: () => import("/home/loic/Web/carpilot/carpilot/pages/signez-vos-documents.vue").then(m => m.default || m)
  },
  {
    name: "votre-ville-slug",
    path: "/votre-ville/:slug(.*)*",
    component: () => import("/home/loic/Web/carpilot/carpilot/pages/votre-ville/[...slug].vue").then(m => m.default || m)
  }
]