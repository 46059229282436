import revive_payload_client_73RU1Y0bPk from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_l4m7etdf4frcmtmpt5k3cpc3gi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LevNR7Xj7M from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_l4m7etdf4frcmtmpt5k3cpc3gi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_V1lSntdhxJ from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_l4m7etdf4frcmtmpt5k3cpc3gi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_qzujm5SU9L from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.9_terser@5._cjcgnt7rdna2u34ievjovi2xxy/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ayV0gwGQ7S from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_l4m7etdf4frcmtmpt5k3cpc3gi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_mqxHgRGoRz from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_l4m7etdf4frcmtmpt5k3cpc3gi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_nQsR7njl4a from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/loic/Web/carpilot/carpilot/.nuxt/components.plugin.mjs";
import prefetch_client_3QKxYAiher from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_l4m7etdf4frcmtmpt5k3cpc3gi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_abDeQfps5w from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.18.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import sentry_client_AMLj9StD8a from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/@sentry+nuxt@8.28.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.25.1_@opentelemetry+api@1._ulfmheeapb2uadxtyycqypkmha/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/loic/Web/carpilot/carpilot/.nuxt/sentry-client-config.mjs";
import slideovers_ihPhxt1XFh from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.9_terser@5.31.1__vue@3.4.31/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_AVlI04DeOB from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.9_terser@5.31.1__vue@3.4.31/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_pKPv7CiEjq from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.9_terser@5.31.1__vue@3.4.31/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_JOp3LWoYa0 from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import view_transitions_client_O5a81B16j6 from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_l4m7etdf4frcmtmpt5k3cpc3gi/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_RRzpd36Yi4 from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_l4m7etdf4frcmtmpt5k3cpc3gi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_auto_pageviews_client_h4iLAllROv from "/home/loic/Web/carpilot/carpilot/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.18.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_73RU1Y0bPk,
  unhead_LevNR7Xj7M,
  router_V1lSntdhxJ,
  _0_siteConfig_qzujm5SU9L,
  payload_client_ayV0gwGQ7S,
  check_outdated_build_client_mqxHgRGoRz,
  plugin_vue3_nQsR7njl4a,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3QKxYAiher,
  plugin_client_abDeQfps5w,
  sentry_client_AMLj9StD8a,
  sentry_client_config_o34nk2sJbg,
  slideovers_ihPhxt1XFh,
  modals_AVlI04DeOB,
  colors_pKPv7CiEjq,
  plugin_client_JOp3LWoYa0,
  view_transitions_client_O5a81B16j6,
  chunk_reload_client_RRzpd36Yi4,
  plugin_auto_pageviews_client_h4iLAllROv
]